import {apiInstance, getContextToken} from "@shopware/api/index.js";

/**
 * Endpoints
 */

const statisticsVisitEndpoint = () => '/store-api/zeit-analytics/statistics/visit';

const useAnalytics = () => {
    async function visit() {
        try {
            await getContextToken();
            await apiInstance.invoke.get(statisticsVisitEndpoint());
        } catch (e) {
        }
    }

    return  {
        visit,
    };
};

export {
    useAnalytics,
};