<template>
  <span class="absolute bottom-0 left-0 h-[1px] w-[1px] opacity-[100%]"></span>
</template>

<script setup>
    import {useAnalytics} from '@shopware/analytics';

    const { visit } = useAnalytics();

    visit();
</script>